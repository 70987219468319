import { Link } from 'gatsby';
import React from 'react';
import Logo from '../../images/logo-white.inline.svg';
import Socials from '../Socials/Socials';
import { copyright_priavcy, footer, logo_container, signup, white_text } from './Footer.module.scss';

const Footer: React.FC = () => {
    return (
        <nav className={footer} data-testid="footer">
            {/* newsletter signup */}
            <div className="row">
                <div className="col-sm-6 px-4 pt-2 pb-0 ms-auto" style={{ textAlign: 'right' }}>
                    <a className={signup} style={{ color: 'white' }} href="/newsletter">
                        Sign Up For The Newsletter
                    </a>
                </div>
            </div>
            {/* end newsletter signup */}

            <div className={`${logo_container} ${white_text}`} data-testid="squarehook-logo-minimal">
                <div className="d-flex justify-content-center align-items-center col-3">
                    <Logo alt="SquareHook Logo Minimal" height="3rem" width="3rem" max-width="1px" />{' '}
                </div>
                {/* Tests by alt tag */}
                <span
                    className={`${copyright_priavcy} d-flex justify-content-center align-items-center col-6`}
                    data-testid="copyright-privacy-text">
                    @2022 SQUAREHOOK.COM |{' '}
                    <Link to="/privacy-terms" data-testid="copyright-privacy-link" style={{ color: 'white' }}>
                        PRIVACY TERMS
                    </Link>
                </span>
                <div className="col-3 d-flex justify-content-center align-items-center">
                    <Socials data-testid="socials" />
                </div>
            </div>
        </nav>
    );
};

export default Footer;
