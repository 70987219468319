import React from 'react';
import FacebookLogo from '../../images/socials/facebook-icon-white.inline.svg';
import InstagramLogo from '../../images/socials/instagram-icon-white.inline.svg';
import LinkedInLogo from '../../images/socials/linkedin-icon-white.inline.svg';
import TwitterLogo from '../../images/socials/twitter-icon-white.inline.svg';
import YoutubeLogo from '../../images/socials/youtube-icon-white.inline.svg';
import { margin_left } from './Socials.module.scss';

const Socials: React.FC = () => {
    return (
        <span data-testid="socials">
            <a
                href="https://www.facebook.com/squarehookIT/"
                target="_blank"
                data-testid="facebook-social-link"
                rel="noreferrer">
                <FacebookLogo className={margin_left} data-testid="facebook-social" />
            </a>
            <a
                href="https://www.instagram.com/squarehook/"
                target="_blank"
                data-testid="instagram-social-link"
                rel="noreferrer">
                <InstagramLogo className={margin_left} data-testid="instagram-social" />
            </a>
            <a
                href="https://www.linkedin.com/company/squarehook/"
                target="_blank"
                data-testid="linkedin-social-link"
                rel="noreferrer">
                <LinkedInLogo className={margin_left} data-testid="linkedin-social" />
            </a>
            <a href="https://twitter.com/SquareHook" target="_blank" data-testid="twitter-social-link" rel="noreferrer">
                <TwitterLogo className={margin_left} data-testid="twitter-social" />
            </a>
            <a
                href="https://www.youtube.com/channel/UCTrnmfwEaGauLM6C8_Fn5CQ"
                target="_blank"
                data-testid="youtube-social-link"
                rel="noreferrer">
                <YoutubeLogo className={margin_left} data-testid="youtube-social" />
            </a>
        </span>
    );
};

export default Socials;
