// The debounce function receives our function as a parameter
const debounce = (fn: Function) => {
  // This holds the requestAnimationFrame reference, so we can cancel it if we wish
  let frame: any;

  // The debounce function returns a new function that can receive a variable number of arguments
  return (...params: any) => {
    // If the frame variable has been defined, clear it now, and queue for next frame
    if (frame) {
      cancelAnimationFrame(frame);
    }

    // Queue our function call for the next frame
    frame = requestAnimationFrame(() => {
      // Call our function and pass any params we received
      fn(...params);
    });
  };
};

// Reads out the scroll position and stores it in the data attribute
// so we can use it in our stylesheets
const storeScroll = () => {
  const limit =
    Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    ) - window.innerHeight;

  if (window.scrollY >= limit) {
    document.documentElement.dataset.scroll = 'true';
  } else {
    document.documentElement.dataset.scroll = 'false';
  }
};

function useDebounceScrolling() {
  // Listen for new scroll events, here we debounce our `storeScroll` function
  document.addEventListener('scroll', debounce(storeScroll), { passive: true });
  // Update scroll position for first time
  storeScroll();
}

export default useDebounceScrolling;
