import { Link } from 'gatsby';
import React, { useRef, useState } from 'react';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';
import Logo from '../../images/squarehook-logo.inline.svg';
import GetStartedButton from '../GetStartedButton/GetStartedButton';
import {
    block,
    disabled,
    hidden,
    icon,
    links_container,
    logo_wrapper,
    my_links,
    my_links_container,
    nav_bar_get_started,
    right_side_container,
    sub_menu_get_started_wrapper,
    topnav,
    topnav_container,
    x_container,
    x_icon,
    x_wrapper,
} from './NavBar.module.scss';

type NavBarProps = {
    location: any;
    updateShowingButton: Function;
};

const NavBar: React.FC<NavBarProps> = ({ location, updateShowingButton }) => {
    const menuRef = useRef(null);

    const handleMenuShow = () => {
        console.log('WORKING!');
        setShowing(true);
        updateShowingButton(false);
    };

    const handleMenuHide = () => {
        setShowing(false);
        updateShowingButton(true);
    };

    const [showing, setShowing] = useState<boolean>(false);

    useOutsideAlerter(menuRef, handleMenuHide, showing);

    const isCurrentPage = (url: string) => {
        if (location === undefined || location === null) return false;
        if (!('pathname' in location)) return false;

        return location.pathname === url;
    };

    return (
        <>
            <div className={`${topnav_container} container-fluid`}>
                <div className={`${topnav} topnav row`} data-testid="topnav">
                    {/*<!-- Top Navigation Menu -->*/}
                    <div
                        // to="/"
                        className="col-2 topnav-active"
                        data-testid="squarehook-logo-link">
                        <Link to="/">
                            <Logo className="navbar-brand" width="12rem" data-testid="squarehook-logo-icon" />
                        </Link>
                    </div>
                    {/*<!-- Navigation links (hidden by default) -->*/}
                    {/* <!-- "Hamburger menu" / "Bar icon" to toggle the navigation links -->*/}
                    <div
                        className={`${right_side_container} col-3 offset-7 col-sm-6 offset-sm-4 col-md-5 offset-md-5  col-lg-4 offset-lg-6 col-xl-3 offset-xl-7 d-flex justify-content-between`}
                        data-testid="right-side-container">
                        <GetStartedButton
                            className={nav_bar_get_started}
                            to={'/solutions'}
                            location={location}
                            data-testid="nav-get-started-button"
                        />
                        <img
                            id="nav-bar-toggle"
                            className={`icon ${icon}`}
                            src="/images/hamburger_menu_icon.png"
                            alt="SquareHook Logo"
                            onClick={handleMenuShow}
                            data-testid="nav-bar-toggle"
                        />
                    </div>
                </div>
                <div id="myLinksContainer" className={`${my_links_container} ${showing ? block : hidden} row`}>
                    <div
                        id="myLinks"
                        className={`${my_links} myLinks ${
                            showing ? block : hidden
                        } col-12 col-sm-6 offset-sm-6 col-md-5 offset-md-7 col-lg-4 offset-lg-8 col-xl-3 offset-xl-9`}
                        ref={menuRef}
                        data-testid="my-links">
                        <div id="nav-links_container" className={links_container}>
                            <div className={`${x_container} row`}>
                                <div className={`${logo_wrapper} col-10`}>
                                    <Link
                                        to="/"
                                        className={`page_link ${isCurrentPage('/') ? disabled : ''}`}
                                        style={{ width: '100%' }}
                                        data-testid="home-link">
                                        Home
                                    </Link>
                                </div>
                                <div className={`${x_wrapper} col-2`}>
                                    <i className={`${x_icon} bi bi-x`} onClick={handleMenuHide} />
                                </div>
                            </div>

                            {/* <Link
                                to="/solutions"
                                className={`page_link ${isCurrentPage('/solutions') ? disabled : ''}`}
                                data-testid="solutions-link">
                                Solutions
                            </Link> */}
                            <Link
                                to="/cloud-cost-control"
                                className={`page_link ${isCurrentPage('/cloud-cost-control') ? disabled : ''}`}
                                data-testid="ccc-link">
                                Cloud Cost Control
                            </Link>
                            <Link
                                to="/managed-it"
                                className={`page_link ${isCurrentPage('/managed-it') ? disabled : ''}`}
                                data-testid="managed-it-link">
                                Managed IT Services
                            </Link>
                            <Link
                                to="/devops"
                                className={`page_link ${isCurrentPage('/devops') ? disabled : ''}`}
                                data-testid="devops-link">
                                DevOps
                            </Link>
                            <Link
                                to="/custom-software"
                                className={`page_link ${isCurrentPage('/custom-software') ? disabled : ''}`}
                                data-testid="custom-software-link">
                                Custom Software
                            </Link>
                            <Link
                                to="/web-development"
                                className={`page_link ${isCurrentPage('/web-development') ? disabled : ''}`}
                                data-testid="web-development-link">
                                Web Development
                            </Link>

                            <Link
                                to="https://chatbot.squarehook.com"
                                className={`page_link`}
                                data-testid="chat-bot-link">
                                Chat Bot
                            </Link>

                            <Link
                                to="/about-us"
                                className={`page_link ${isCurrentPage('/about-us') ? disabled : ''}`}
                                data-testid="about-us-link">
                                About Us
                            </Link>
                            <Link
                                to="/contact-us"
                                className={`page_link ${isCurrentPage('/contact-us') ? disabled : ''}`}
                                data-testid="contact-us-link">
                                Contact Us
                            </Link>
                            <div className={sub_menu_get_started_wrapper}>
                                <GetStartedButton to={'/solutions'} location={location} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NavBar;
