import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { ParallaxProvider } from 'react-scroll-parallax';
import '../../custom.scss';
import '../../global.scss';
import { configureStore } from '../../store';
import useDebounceScrolling from '../../util/debounce-scroll-event';
import Footer from '../Footer/Footer';
import GetStartedButton from '../GetStartedButton/GetStartedButton';
import NavBar from '../NavBar/NavBar';
import SEO from '../Seo/Seo';
import { container, get_started_button_responsive, hiding_overflow, main_content } from './Layout.module.scss';

type LayoutProps = {
    children: any;
    location: any;
};

const Layout: React.FC<LayoutProps> = ({ children, location }) => {
    const [showGetStartedButton, setShowGetStartedButton] = useState<boolean>(true);

    useEffect(() => {
        useDebounceScrolling();
    }, []);

    const updateShowingButton = (value: boolean) => {
        setShowGetStartedButton(value);
    };

    return (
        <>
            <SEO location={location} />
            <Provider store={configureStore()}>
                <ParallaxProvider>
                    {/* <IndexLoader loaders={[]} location={location}> */}
                    {showGetStartedButton ? (
                        <div
                            className={`${get_started_button_responsive} get_started_button_responsive`}
                            data-testid="get-started-button-div-container-layout">
                            <GetStartedButton location={location} to="/solutions" />
                        </div>
                    ) : null}
                    <div className={`${container} container-fluid`} data-testid="top-level-parent-div">
                        <div className={hiding_overflow}>
                            <NavBar
                                location={location}
                                data-testid="nav-bar"
                                updateShowingButton={updateShowingButton}
                            />
                            <div id="primary" className={main_content} data-testid="main-content">
                                {children}
                            </div>
                            <Footer data-testid="footer" />
                        </div>
                    </div>
                    {/* </IndexLoader> */}
                </ParallaxProvider>
            </Provider>
        </>
    );
};

export default Layout;
