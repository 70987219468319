import { Link } from 'gatsby';
import React, { useState } from 'react';
import { getting_started_button, pressed } from './GetStartedButton.module.scss';

type GetStartedButtonProps = {
  to: string;
  location: any;
  className?: any;
};

const GetStartedButton: React.FC<GetStartedButtonProps> = ({ to, location, className }) => {
  const [isPressed, setIsPressed] = useState<boolean>(false);
  return (
    <Link
      className={`${getting_started_button} ${isPressed ? pressed : ''} ${className}`}
      onClick={() => {
        if (location.pathname === to) return;
        setIsPressed(true);
      }}
      to={to}
      role="button"
      data-testid="get-started-button">
      Get Started
    </Link>
  );
};

export default GetStartedButton;
